$(function(){

    var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
      dirRtlFlag = true;
    }
  
    function isMobile() {
          return $(window).innerWidth() <= 1024;
    }
  
    $('.player__volume-btn').click(function(){
      if($(this).hasClass('player__volume-btn_off')){
        $(this).removeClass('player__volume-btn_off');
        $(this).find('.fa-volume-off').removeClass('fa-volume-off').addClass('fa-volume-up');
        $('#slide_1 .video-play').YTPUnmute();
      } else {
        $(this).addClass('player__volume-btn_off');
        $(this).find('.fa-volume-up').removeClass('fa-volume-up').addClass('fa-volume-off');
        $('#slide_1 .video-play').YTPMute();
      }
    });
  
  
    $('#hp-banners').on('init', function(){
      if($("#slide_1").data("type") == 'video'){
        $("#slide_1").attr('data-videoready',1);
        $("#slide_1 .video-play").YTPlayer();
        if (isMobile()) {
          var wpBanner = $('.video-play').closest('.wrapper-banners');
          wpBanner.css({'height':'320px'});
          jQuery('.video-play').on("YTPReady",function(e){
            var wpBanner = $(this).closest('.wrapper-banners');
            wpBanner.css({'height':'auto'});
          });
        }
        jQuery('#slide_1 .video-play').on("YTPEnd",function(e){
           $('#hp-banners').slick('slickNext');
        });
      }else{
        setTimeout(function(){$("#hp-banners").slick('slickPlay');},100);
        // $('#hp-banners').slick('slickNext');
      }
    });
    $('#hp-banners').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      doSlide(currentSlide, nextSlide)
    });
  
    function doSlide(currentSlide, nextSlide){
  
      $("#hp-banners").slick('slickPause');
      currentSlide += 1;
      nextSlide += 1;
      if($("#hp-banners .banner-item:nth-child("+currentSlide+")").data("type") == 'video'){
        $("#slide_"+currentSlide+" .video-play").YTPStop();
      }
      if($("#hp-banners .banner-item:nth-child("+nextSlide+")").data("type") == 'video'){
        if($("#slide_"+nextSlide).attr('data-videoready') == 1){
          $("#slide_"+nextSlide+" .video-play").YTPPlay();
        }else{
          $("#slide_"+nextSlide).attr('data-videoready',1);
          $("#slide_"+nextSlide+" .video-play").YTPlayer();
          jQuery('#slide_'+nextSlide+' .video-play').on("YTPEnd",function(e){
             $('#hp-banners').slick('slickNext');
          });
        }
      }else{
        $("#hp-banners").slick('slickPlay');
      }
    }
  
    $('#hp-banners').slick({
      dots: false,
      infinite: true,
      speed: 1000,
      fade: true,
      rtl: dirRtlFlag,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000,
      cssEase: 'linear',
      adaptiveHeight: true,
      pauseOnHover: false
    });
  
    $(".volume-control").click(function(){
      var index = $(this).data("slider");
      if($(this).hasClass('volume-on')){
        $(this).removeClass('volume-on').addClass('volume-off');
        $("#slide_"+index+" .video-play").YTPMute();
  
      }else{
        $(this).removeClass('volume-off').addClass('volume-on');
        $("#slide_"+index+" .video-play").YTPUnmute();
      }
    });

});

  
// $(function() {

//   var dirRtlFlag = false;
//   if ($("html").attr('dir') == 'rtl') {
//     dirRtlFlag = true;
//   }

//   $('#hp-banners').slick({
//     dots: false,
//     infinite: true,
//     speed: 1000,
//     fade: true,
//     rtl: dirRtlFlag,
//     arrows: false,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     cssEase: 'linear',
//     pauseOnHover: false
//   });

//   $(window).scroll(function(){
//     var x = $(this).scrollTop(); /* scroll pos */
//     var y = $("html").height();
//     var z = x / y;

//     curTop = (((z*800)+0));
//     $("#banners-scroll").css("top", (curTop+0)+"px");
//   });

//   // var hasWebRTC = navigator.getUserMedia ||
//   //                 navigator.webkitGetUserMedia ||
//   //                 navigator.mozGetUserMedia ||
//   //                 navigator.msGetUserMedia;

//   // if (!hasWebRTC) {
//   //   $('.wrap-banner-scroll').css('position','static');
//   // }

//     // Get IE or Edge browser version
//     var version = detectIE();
  
//     // if (version === false) {
//     //   document.getElementById('result').innerHTML = '<s>IE/Edge</s>';
//     // } else if (version >= 12) {
//     //   document.getElementById('result').innerHTML = 'Edge ' + version;
//     // } else {
//     //   document.getElementById('result').innerHTML = 'IE ' + version;
//     // }
  
//     // add details to debug result
//     // document.getElementById('details').innerHTML = window.navigator.userAgent;
  
//     /**
//      * detect IE
//      * returns version of IE or false, if browser is not Internet Explorer
//      */
//     function detectIE() {
//       var ua = window.navigator.userAgent;
  
//       var msie = ua.indexOf('MSIE ');
//       if (msie > 0) {
//         // IE 10 or older => return version number
//         return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
//       }
  
//       var trident = ua.indexOf('Trident/');
//       if (trident > 0) {
//         // IE 11 => return version number
//         var rv = ua.indexOf('rv:');
//         return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
//       }
//       // var edge = ua.indexOf('Edg/');
//       // if (edge > 0) {
//       //   // Edge (IE 12+) => return version number
//       //   // console.log(ua.substring(edge + 4, ua.indexOf('.', edge)));
//       //   return parseInt(ua.substring(edge + 4, ua.indexOf('.', edge)), 10);
//       // }
  
//       //  var Firefox = ua.indexOf('Firefox/');
//       // if (Firefox > 0) {
//       //   return parseInt(ua.substring(Firefox + 8, ua.indexOf('.', Firefox)), 10);
//       // }
  
//       // other browser
//       return false;
//     }
  
//     if (version) {
//       $('.wrap-banner-scroll').css('position', 'static');
//     } else {
      
//     }
    
// });
