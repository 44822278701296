$(document).ready(function() {

	// PARALLAX EFFECT

	$(window).scroll(function() {
		var e = $(this).scrollTop(),
			t = $("html").height();
		curTop = 800 * (e / t) + 0, $("#banners-scroll").css("top", curTop + 0 + "px")
	});


	// SLIDERS

	if ($('.slider_main_block').length) {

		// $('.slider_main_block').slick({
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	arrows: false,
		// 	dots: false,
		// 	rtl: true,
		// 	autoplay: true,
		// 	pauseOnHover: false
		// });
	}

	$('.projects_wrap .slider').not('.projects_wrap2 .slider').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: '.projects_wrap .arrow_left',
		nextArrow: '.projects_wrap .arrow_right',
		dots: false,
		rtl: true,
		autoplay: true,
		pauseOnHover: false,
		responsive: [{
				breakpoint: 1301,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 651,
				settings: {
					slidesToShow: 1,
					arrows: false,
				}
			}
		]
	});

	$('.news_wrap .slider').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		rtl: true,
		autoplay: true,
		pauseOnHover: false,
		responsive: [{
				breakpoint: 1301,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 851,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 651,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});

	$('.logos_wrap .slider').slick({
		slidesToShow: 10,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: '.logos_wrap .arrow_left',
		nextArrow: '.logos_wrap .arrow_right',
		dots: false,
		rtl: true,
		autoplay: true,
		pauseOnHover: false,
		responsive: [{
				breakpoint: 1301,
				settings: {
					slidesToShow: 7,
					arrows: false
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
					arrows: false
				}
			},
			{
				breakpoint: 651,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			}
		]
	});


});